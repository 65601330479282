import axios from "@/plugins/pexelsApiService.js";
const state = {
  videos: [],
  images : []
};

// mutations
const mutations = {
  SET_VIDEOS(state, value) {
    state.videos = []
    setTimeout(() => {
      state.videos = value;
    }, 20);
  },
  SET_IMAGES(state, value) {
    state.images = value;
  },
};

// getters
const getters = {
  getVideosData(state) {
    return state.videos;
  },
  getImagesData(state) {
    return state.images;
  },
};
//actions
const actions = {
  async searchVideos({ commit },filter) {
    let resp = await axios.get(`/videos/search?query=${filter.query ? filter.query : 'random'}&page=${filter.page}&per_page=${filter.per_page}&orientation=landscape&size=medium`);
    // let resp = await axios.get(`/videos/popular?orientation=landscape`);
    console.log("video resp", resp);
    if (resp.status == 200) {
      var videosData = resp.data.videos
      commit("SET_VIDEOS", videosData);
    }
    return resp;
  },
  async searchImages({ commit },filter) {
    let resp = await axios.get(`/v1/search?query=${filter.query ? filter.query : 'random'}&page=${filter.page}&per_page=${filter.per_page}`);
    // let resp = await axios.get(`/videos/popular?orientation=landscape`);
    console.log("image resp", resp);
    if (resp.status == 200) {
      var photosData = resp.data.photos;
      commit("SET_IMAGES", photosData);
    }
    return resp;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
