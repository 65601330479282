<template>
  <div :class="isEditorPage">
    <router-view />
    <gc-login :email="user.email" :access-token="user.gc_token"></gc-login>
  </div>
</template>

<script>

import { defineComponent, nextTick, onMounted } from "vue"
import { useStore } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { initializeComponents } from "@/core/plugins/keenthemes"
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: "app",
  computed: {
    ...mapGetters({
      user: 'Auth/user'
    }),
    isEditorPage() {
      if (this.$route.name === 'QuizDetailNew') {
        return document.body.style.background = '#333867'
      } else if (this.$route.name === 'Home' || this.$route.name === 'VideoTutorials' || this.$route.name === 'Domain') {
        return document.body.style.background = '#F2F5F8'
      } else {
        return document.body.style.background = '#333867'
      }
      // return this.$route.name === 'LeadPage'
      // || this.$route.name === 'Gallery'
      // || this.$route.name === 'Editor'
      // || this.$route.name === 'QuizDetailNew'
      //     ? document.body.style.background = '#202020' : document.body.style.background = '';
    }
  },
  setup() {
    const store = useStore()

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)

      nextTick(() => {
        initializeComponents()
      })

      // document.body.style.background = '#333867';
    })
  },
  mounted() {
    window.addEventListener("GCUserAuthorized", (e) => {
      const { accessToken, user } = e.detail;
      var payload = {
        user_id: this.user.id,
        gc_token: accessToken,
        email: user && user.email ? user.email : null,
      };
      this.updateGcToken(payload).then(() => this.checkGCupdate());
    });

    window.addEventListener("GCUserUnAuthorized", () => {
      window.GCLogout();
      this.logoutGc();
      console.log("logout");
    });
  },
  methods: {
    ...mapActions({
      updateGcToken: "Auth/updateGcToken",
      logoutGc: "Auth/logoutGc",
      checkGCupdate: "GC/checkGCupdate",
    }),
  }
})
</script>
<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
//@import "~element-plus/lib/theme-chalk/index.css";

// Main demo
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
<style lang="scss">
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}
</style>
<style>
@media (min-width: 992px) {
  .header-fixed[data-kt-sticky-header=on] .header {
    padding-left: 98px;
  }
}
</style>
