import axios from '@/plugins/apiService.js' 
const state = {
    getTotalPieChart:{
        total_entries: 0,
        disqualified: 0,
        qualified: 0,
        general: 0,
        hot: 0,
        perHot: 0,
        perQual: 0,
        perstand: 0,
        perdisq: 0,
        pie_chart: [
            {
                value: 0,
                name: "General",
                color: "#F1416C",
                devided_value: 0,
                original_per: 0
            },
            {
                value: 0,
                name: "Disqual",
                color: "#5470eb",
                devided_value: 0,
                original_per: 0
            },
            {
                value: 0,
                name: "Qual",
                color: "#E38600",
                devided_value: 0,
                original_per: 0
            },
            {
                value: 0,
                name: "Hot",
                color: "#E4E6EF",
                devided_value: 0,
                original_per: 0
            }
        ]
    },
    topCampaignContact:{
        dis_leads: [],
        q_lead: [],
        g_lead: [],
        top_cart_name: [],
        top_cart_data: []
    }
};


// mutations
const mutations = {
  SET_TOTAL_AND_PIE(state, value) {
        state.getTotalPieChart = value
  },
  SET_TOP_CAM(state,value){
      state.topCampaignContact=value
  }
}

// getters
const getters = {
  getTotalPieData(state){
    return state.getTotalPieChart
  },
  getCamContacts(state){
      return state.topCampaignContact
  }
}
//actions
const actions = {
  /** User login  **/
  async getTotalPieChartData({ commit }) {
    let resp = await axios.get('/api/get-total-pie-chart')
    if(resp.data.status==true){
      commit('SET_TOTAL_AND_PIE',resp.data.data)
    }
    return resp;
  },
  async getMapData() {
    let resp = await axios.get('/api/get-map-data')
    return resp;
  },
  async getTopCampaignContact({ commit }) {
    let resp = await axios.get('/api/top-campaign-contact')
    if(resp.data.status==true){
      commit('SET_TOP_CAM',resp.data.data)
    }
    return resp;
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
