import axios from 'axios';
// import { useStore } from 'vuex'
const customAxios = axios.create({
    baseURL: 'https://api.dev2.ghdev.cc',
    // timeout: 10000, 
});
const requestHandler = request => {
    // let idToken = store.state.Auth.user.gc_token;
    let idToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2M2RjMjA5MDQ5OWNlYzIyNWJiMTI2NTAiLCJrZXkiOiIyYTc4NDNhMjZmMTZmYTQ5MzkxNzA0MjZhYjk0ODJiMiIsImlkIjoiNjQwNzUwNTEyMzhmMjI1NjdmZjNiYzdiIiwiaWF0IjoxNjc4MjAwOTEzLCJleHAiOjE2Nzg0NjAxMTN9.Jmx79R6ikRSnx0MSt2C6ijGkjlD9YWjEuCGRFQ-3Yaw';
    request.headers.Authorization = 'Bearer '+idToken;  
    return request;
};
const responseHandler = response => {
   return response;
};

const errorHandler = error => {
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );


export default customAxios;