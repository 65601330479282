// import axios from "axios";
import axios from '@/plugins/apiService.js' 

const state = {
  videoCategories : [],
  tutorialVideos : [],
  loginSceen : {},
  screenType : '',
  screenId : '',
};

const getters = {
  getVideoCategories(state){
    return state.videoCategories
  },
  getTutorialVideos(state){
    return state.tutorialVideos
  },
  getLoginScreenObj(state){
    return state.loginSceen
  },
  getScreenType(state){
    return state.screenType
  },
  getScreenId(state){
    return state.screenId
  },
};

const mutations = {
  SET_VIDEO_CATEGORIES(state,value){
    state.videoCategories = value
  },
  SET_TUTORIAL_VIDEOS(state,value){
    state.tutorialVideos = value
  },
  SET_LOGIN_SCREEN(state,value){
    state.loginSceen = value
  },
  SET_SCREEN_TYPE(state,value){
    state.screenType = value
  },
  SET_SCREEN_ID(state,value){
    state.screenId = value
  }
};
const actions = {
  async setVideoCategories({commit}) {
    var payload = {
      company_id : 73
    }
    let resp = await axios.post('https://app.saasonboard.com/api/tutorial-video/categories/get', payload)
    if (resp.data.status == true) {
      commit('SET_VIDEO_CATEGORIES', resp.data.data)
    }
    return resp;
  },
  async setTutorialVideos({commit},payload) {
    let resp = await axios.post('https://app.saasonboard.com/api/tutorial-video/get', payload)
    if (resp.data.status == true) {
      commit('SET_TUTORIAL_VIDEOS', resp.data.data)
    }
    return resp;
  },
  async getIntroScreen({commit}) {
    let payload = {
      unique_code : 'SvbrVZCfz9uEaZu',
    }
    let resp = await axios.post('https://app.saasonboard.com/api/login-screen/get', payload)
    if (resp.data.status == true) {
      commit('SET_LOGIN_SCREEN', resp.data.data.screen)
      commit('SET_SCREEN_TYPE', resp.data.data.type)
      commit('SET_SCREEN_ID', resp.data.data.screen_id)
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async addloginImpressions({} , payload) {
    let resp = await axios.post(`https://app.saasonboard.com/api/login-screen/add-impressions`, payload)
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async addLoginClick({} , payload) {
    let resp = await axios.post(`https://app.saasonboard.com/api/login-screen/add-clicks`, payload)
    return resp;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
