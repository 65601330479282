import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/index.js";
import store from "./store/index.js";
// import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import '../src/assets/sass/mainStyle.css'
const options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    timeout:2000,
    newestOnTop: true
};
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from "vue3-apexcharts";    
import vClickOutside from "click-outside-vue3"


const app = createApp(App);
// console.log('prcoeess',process.env)
app.use(store);
app.use(router);
// app.use(ElementPlus);
app.use(Toast, options);
app.use(VueSweetalert2);
ApiService.init(app);
app.use(VueApexCharts);
app.use(vClickOutside)
// initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
