export default [
    {
        path: '/quiz',
        name: 'QuizIndex',
        component:() => import("@/views/quiz/IndexOld.vue"),
        meta: {
            requiresAuth: true,
            isAgency:true
        },
      
    },
    {
        path: '/quiz/details/:id',
        name: 'QuizDetail',
        component: () => import("@/views/quiz/QuizDetail.vue"),
        meta: {
            requiresAuth: true,
            isAgency:true
        },
      
    },
    {
        path: '/quizzes',
        name: 'QuizIndexNew',
        component:() => import("@/views/quiz/Index.vue"),
        meta: {
            requiresAuth: true,
            isAgency:true
        },
      
    },
    {
        path: '/quiz-detail/:id',
        name: 'QuizDetailNew',
        component:() => import("@/views/quiz/Detail.vue"),
        meta: {
            requiresAuth: true,
            isAgency:true
        },
      
    },
    {
        path: '/domain',
        name: 'Domain',
        component:() => import("@/views/domain/Index.vue"),
        meta: {
            requiresAuth: true,
            isAgency:true
        },
      
    },
];	