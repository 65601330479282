import axios from '@/plugins/apiService.js' 
import router from '@/router';
const state = {
  authenticated:false,
  user:'',
  idToken:'',
  tags : []
};


// mutations
const mutations = {
  SET_AUTHENTICATED (state, value) {
      state.authenticated = value
  },
  SET_USER (state, value) {
      state.user = value
  },
  SET_ID_TOKEN(state, value){
      state.idToken = value
  },
  SET_TAGS(state, value){
      state.tags = value
  }
}

// getters
const getters = {
  authenticated(state){
      return state.authenticated
  },
  user(state){
      return state.user
  },
  getIdToken(state){
      return state.idToken
  },
  getTags(state){
      return state.tags
  }
}
//actions
const actions = {
  /** User login  **/
  async login({ commit }, data) {
    let resp = await axios.post('/api/login', data)
    if(resp.data.status==true){
      commit('SET_USER',resp.data.data.user_data)
      commit('SET_ID_TOKEN',resp.data.data.accesstoken)
      commit('SET_AUTHENTICATED',true)
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async register({ commit }, data) {
    let resp = await axios.post('/api/register', data)
    return resp;
  },
// eslint-disable-next-line no-empty-pattern
  logout({ commit }){
    // let resp = axios.post('/api/logout')
      commit('SET_USER','')
      commit('SET_ID_TOKEN','')
      commit('SET_AUTHENTICATED',false)
      router.push({name: 'login'})
    // return resp;
  },
  async connectGc({state,commit},data){
    let resp = await axios.post('/api/connect-to-gc',data)
    state.user.gc_email=resp.data.data.gc_email
    state.user.gc_id=resp.data.data.gc_id
    state.user.gc_token=resp.data.data.gc_token
    let modified_user=state.user
    commit('SET_USER',modified_user)
    return resp;
  },
  logoutGc({state,commit}){
    // state.user.gc_email=null
    // state.user.gc_id=null
    // state.user.token=null 
    let user=state.user
    user.gc_email=null
    user.gc_id=null
    user.gc_token=null 
    commit('SET_USER',user)
    let resp = axios.get('/api/disconnect-to-gc')
    return resp
  },
  async updateGcToken({commit},data){
   let resp = await axios.post("/api/gc_token/update",data)
    if (resp.data.status == true) {
      commit('SET_USER', resp.data.data.user)
    }
    return resp
  }, 
  async getTagList({ state,commit }) {
    if(state.user){
      let resp = await axios.get("https://api.globalcontrol.io/api/tagging/tags/list", {
        headers: {
          Authorization: `Bearer ${state.user.gc_token}`,
          "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.71 Safari/537.36",
          Origin: "https://dev.su.ghdev.cc",
          "Content-Type": "application/json",
        },
      });
      if (resp.data.status) {
        commit("SET_TAGS",resp.data.data);
      }
      return resp;
    }
  },
  async getTagNameById({ state }, tagId) {
    // var userData = store.getters["auth/user"];
    var payload = {
      data: {
        _id : tagId
      }
    }
    let resp = await axios.post("https://api.globalcontrol.io/api/tagging/tags/find",payload,{
      headers: {
        Authorization: `Bearer ${state.token}`,
        "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.71 Safari/537.36",
        Origin: "https://app.poplinks.io/",
        "Content-Type": "application/json",
      },
    });
    if(resp.status == 200){
      var response = resp.data.data
      if(response)
      return response.title
    }
    return null
  },
  // eslint-disable-next-line no-empty-pattern
  async sendForgetPasswordEmail({},payload) {
    let resp = await axios.post('https://app.saasonboard.com/api/send-forget-email', payload)
    return resp;
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
